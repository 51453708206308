import React, { useEffect, useRef, useState } from "react";
import Section from "../Section";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { useOutsideClick } from "../../../../common/hooks";

const items = (_t) => [
  {
    title: _t("games_card_1_title"),
    text: _t("games_card_1_text"),
    preview: _t("games_card_1_preview"),
    video: _t("games_card_1_video"),
    link: _t("games_card_1_link"),
  },
  {
    title: _t("games_card_2_title"),
    text: _t("games_card_2_text"),
    preview: _t("games_card_2_preview"),
    video: _t("games_card_2_video"),
    link: _t("games_card_2_link"),
  },
  {
    title: _t("games_card_3_title"),
    text: _t("games_card_3_text"),
    preview: _t("games_card_3_preview"),
    video: _t("games_card_3_video"),
    link: _t("games_card_3_link"),
  },
  {
    title: _t("games_card_4_title"),
    text: _t("games_card_4_text"),
    preview: _t("games_card_4_preview"),
    video: _t("games_card_4_video"),
    link: _t("games_card_4_link"),
  },
];
const GamesCards = () => {
  const games = items(_t);
  const [isVideoModalOpened, setIsVideoModalOpened] = useState(false);
  const [modalVideo, setModalVideo] = useState("");
  const [modalVideoPreview, setModalVideoPreview] = useState("");
  const videoSectionContentRef = useRef(null);
  const videoElement: any = useRef(null);

  const handleCloseModal = () => {
    setIsVideoModalOpened(false);
  };
  const handleOpenModal = (video) => {
    setModalVideo(video);
    setIsVideoModalOpened(true);
  };
  useOutsideClick(videoSectionContentRef, handleCloseModal);
  return (
    <Section id="games-cards" sectionClass="games-cards-section">
      <div
        className="games-cards-section__content"
        ref={videoSectionContentRef}
      >
        <div className="games-grid">
          {games.map((item, idx) => (
            <div className="games-grid__item" key={idx}>
              <div className="game-card">
                <div className="game-card__image">
                  <img src={item.preview} loading="lazy" alt="" />
                </div>
                <div className="game-card__content">
                  <h3 className="game-card__title">{item.title}</h3>
                  <h6 className="game-card__text">{item.text}</h6>
                  <button
                    className="homepage-button"
                    onClick={() => {
                      handleOpenModal(item.video);
                      setModalVideoPreview(item.preview);
                    }}
                  >
                    <span>{_t("button_text_watch")}</span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isVideoModalOpened && (
          <div className="game-modal">
            <div className="game-modal__bg" onClick={handleCloseModal} />
            <div className="game-modal__content">
              <video
                controls
                ref={videoElement}
                poster={modalVideoPreview}
                preload="metadata"
              >
                <source src={modalVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

export default GamesCards;
