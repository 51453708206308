import React from "react";
import { Form, Row, Col, Space, Button } from "antd";
import { SelectInput } from "../../../Common/components";
import { SettingOutlined } from "@ant-design/icons";
import { CheckBoxSelect } from "../../../Common/components/CheckBoxSelect";
import { _t } from "../../../Common/components/InjectIntlContext";

export const SettingsForm = ({
  onSubmit,
  initialValues,
  columnsOptions,
  showGroupBy,
  updateTime,
}) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <Row align="middle" gutter={5}>
        {/*<Col>*/}
        {/*  <Form.Item*/}
        {/*    label={_t("time_ago_title") + ": " + updateTime}*/}
        {/*    colon={false}*/}
        {/*  ></Form.Item>*/}
        {/*</Col>*/}
        <Col flex="auto">
          {showGroupBy && (
            <Form.Item name="groupBy" style={{ width: 150 }}>
              <SelectInput
                allowClear={false}
                onDropdownVisibleChange={(open) => !open && form.submit()}
                options={[
                  { value: "day", label: _t("days") },
                  { value: "total", label: _t("total") },
                ]}
              />
            </Form.Item>
          )}
        </Col>
        <Col flex="0 0 auto">
          <Space>
            <Form.Item name="page_size" label={_t("show_per_page")}>
              <SelectInput
                allowClear={false}
                onDropdownVisibleChange={(open) => !open && form.submit()}
                options={[
                  { value: "100", label: "100" },
                  { value: "500", label: "500" },
                  { value: "1000", label: "1000" },
                ]}
              />
            </Form.Item>
            <Form.Item name="columns">
              <CheckBoxSelect
                onDropdownVisibleChange={(open) => {
                  !open && form.submit();
                }}
                options={columnsOptions}
              >
                <Button
                  style={{ borderRadius: 30 }}
                  size="large"
                  icon={<SettingOutlined style={{ lineHeight: 0 }} />}
                >
                  {_t("settings")}
                </Button>
              </CheckBoxSelect>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
