import { useMutation, useQueryCache } from "react-query";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { notify } from "../../../../../common/helpers";
import { postFindGamers } from "../api";

export const useApiPostFindGamers = () => {
  const queryCache = useQueryCache();
  const successMsg = _t("successfully_updated");

  return useMutation(postFindGamers, {
    onSuccess: (data, variables) => {
      // notify("success", successMsg);
    },
  });
};
