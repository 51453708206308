import React, { useState } from "react";
import { Card } from "antd";
import { DateRangePicker } from "../../../../../../common/components";
import { Button } from "../../../../../Common/components";
import { _t } from "../../../../../Common/components/InjectIntlContext";
import { ExcelButton } from "../../../../components/Filters/ExcelButton";

const Filters = ({ filters, setFilters }) => {
  const [dates, setDates] = useState(filters.dates);
  return (
    <Card>
      <div className="tech-filters-wrap">
        <div className="tech-filters-wrap__date">
          <DateRangePicker
            onChange={(dates) => {
              console.log("===", dates);
              setDates({
                from: dates[0],
                to: dates[1],
              });
            }}
            value={dates}
          />
        </div>
        <Button
          onClick={() =>
            setFilters({
              ...filters,
              dates: {
                ...dates,
              },
            })
          }
          title={_t("Apply")}
        />
        <ExcelButton clickHandler={() => ({})} isLoading={false} />
      </div>
    </Card>
  );
};

export default Filters;
