import React, { useEffect } from "react";

const Section = ({ sectionClass, id, children }) => {
  return (
    <section className={`custom-section ${sectionClass}`} id={id}>
      <div className="section-anchor" id={`anchor-${id}`} />
      {children}
    </section>
  );
};

export default Section;
