import React from "react";
import { useDispatch } from "react-redux";
import AuthForm from "../components/AuthForm";
import {
  getItem,
  isAuthorized,
  setItem,
} from "../../../system/helpers/localstorageHelper";
import { Redirect } from "react-router-dom";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { useSignin } from "../hooks";
import { _t } from "../../Common/components/InjectIntlContext";
import { returnAdminHelper } from "../../../system/helpers/localstorageHelper";
import AuthTemplate from "../../Layouts/AuthTemplate";
import { Homepages } from "../../../layouts";

interface LoginProps {
  accessCheck?: boolean;
}

const Login: React.FC<LoginProps> = ({ accessCheck }) => {
  const [useSigninHandler] = useSignin(_t);
  const dispatch = useDispatch();
  const Submit = (values) => {
    setItem("isShowNotifications", true);

    if (accessCheck) {
      returnAdminHelper();
    }
    dispatch(useSigninHandler(values));
  };

  if (isAuthorized() && !accessCheck) {
    if (getItem("role") === "admin") {
      return <Redirect to={APP_ROUTES.dashboard.admin} replace />;
    }
    return <Redirect to={APP_ROUTES.dashboard.general} replace />;
  }

  return (
    <Homepages isAuth>
      <AuthForm onSubmit={Submit} />
    </Homepages>
  );
};

export default Login;
