import React from "react";
import { Homepages } from "../../../layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { useGetTerms } from "./helpers";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { Spin } from "antd";

const Terms = () => {
  const { data, isLoading } = useGetTerms({
    language: getLocale(),
    name: "conditions",
  });
  return (
    <Homepages>
      <section className="homepages-page terms-page">
        <h2 className="section-title section-title--small-indent">
          {_t("conditions")}
        </h2>
        <Spin spinning={isLoading}>
          <div
            className="terms-content"
            dangerouslySetInnerHTML={{ __html: data?.text }}
          ></div>
        </Spin>
      </section>
    </Homepages>
  );
};

export default Terms;
