import React, { useEffect, useMemo } from "react";
import { Spin } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { useResetPassword } from "../apiHooks";
import { useUrlQuery } from "../utils";
import { Link } from "react-router-dom";
import { Homepages } from "../../../layouts";

export function ResetPassword() {
  const query = useUrlQuery();
  const key = query.get("key");
  const email = query.get("email");

  const [resetPassword, { isLoading, isSuccess, data }] = useResetPassword({
    onSuccess: (res) => console.log(res.data),
  });

  useEffect(() => {
    if (email && key) {
      resetPassword({ email, key });
    }
  }, [key, email]);

  const error = useMemo(() => {
    return isSuccess && data?.data?.status === "error";
  }, [isSuccess]);

  return (
    <div className="h-100per fx-center fxDirCol text-center">
      {isLoading && key && email && (
        <>
          <Spin spinning={isLoading}></Spin>
          {_t("Reset password is in progress, please wait...")}
        </>
      )}
      {error && <>{_t("Something went wrong")}</>}
      {!key ||
        (!email && <>{_t("Url is not valid, please contact your admin")}</>)}
      {!error && data && (
        <Homepages isAuth noImage>
          <h2 className="section-title section-title--small-indent">
            {_t("Your new password is:")}
          </h2>
          <p>{data?.data?.new_password}</p>
          <Link className="homepage-button">
            <span>{_t("sign_in")}</span>
          </Link>
        </Homepages>
      )}
    </div>
  );
}
