import React from "react";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import { validationRules } from "./constants";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { Link } from "react-router-dom";

const AuthForm = ({ onSubmit }) => {
  return (
    <Form name="auth_form" onFinish={onSubmit} className="homepage-form">
      <h2 className="section-title section-title--small-indent">
        {_t("sign_in")}
      </h2>
      <p className="homepage-form__subtitle">
        {_t("dont_have_account") + " "}
        <Link to={APP_ROUTES.registration}>{_t("sign_up")}!</Link>
      </p>
      <Form.Item name="login" rules={validationRules.login}>
        <Input placeholder={_t("Login")} />
      </Form.Item>
      <Form.Item name="password" rules={validationRules.password}>
        <Input.Password type="password" placeholder={_t("Password")} />
      </Form.Item>
      <button
        className="homepage-button homepage-button--gradient"
        type="submit"
      >
        <span>{_t("sign_in")}</span>
      </button>
      <Link to={APP_ROUTES.user.restore_password}>{_t("forgot_password")}</Link>
    </Form>
  );
};

export default AuthForm;
