import React, { useEffect, useState } from "react";
import { ReactComponent as ClockIcon } from "./assets/icons/clock-icon.svg";
import { ReactComponent as UpdateIcon } from "./assets/icons/history-icon.svg";
import "./index.scss";
import { useStatsUpdateTime } from "../../../../Dashboard/apiHooks";

const UpdateDate = () => {
  const {
    isSuccess: statsUpdateTimeSuccess,
    data: updateTime,
  } = useStatsUpdateTime();

  const time = statsUpdateTimeSuccess ? updateTime.data : "--:--";
  const [today, setDate] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const currentTime = today.toLocaleTimeString("en", {
    hour: "numeric",
    hour12: false,
    minute: "numeric",
    second: "numeric",
  });
  return (
    <div className="update-time">
      <div className="update-time__item">
        <div className="update-time__icon">
          <ClockIcon />
        </div>
        {currentTime}
      </div>
      <div className="update-time__item">
        <div className="update-time__icon">
          <UpdateIcon />
        </div>
        {time}
      </div>
    </div>
  );
};

export default UpdateDate;
