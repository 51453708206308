import React from "react";
import { Link, useHistory } from "react-router-dom";
import { isAuthorized } from "../../../system/helpers/localstorageHelper";
import { Redirect } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import Button from "../../Common/components/Button";
import { Typography } from "antd";
import { useRestorePassword } from "../apiHooks";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import AuthTemplate from "../../Layouts/AuthTemplate";
import { Homepages } from "../../../layouts";

export const ForgotPassword = () => {
  const history = useHistory();
  const [submit, { isSuccess }] = useRestorePassword();

  if (isAuthorized()) {
    return <Redirect to={APP_ROUTES.dashboard.general} replace />;
  }

  const form = (
    <Form onFinish={submit} layout="vertical" className="homepage-form">
      <Form.Item
        name="email"
        label={_t("Please enter your email address")}
        rules={[
          {
            type: "email",
            message: _t("not_valid_email_message"),
          },
          {
            required: true,
            message: _t("input_email_message"),
          },
        ]}
      >
        <Input placeholder={_t("E-mail")} />
      </Form.Item>
      <button
        className="homepage-button homepage-button--gradient"
        type="submit"
      >
        <span>{_t("Remind")}</span>
      </button>
      <Link to={APP_ROUTES.login.main}>{_t("sign_in")}</Link>
    </Form>
  );

  const success = (
    <Typography.Text>
      {_t("Please visit your email and follow the instruction")}
    </Typography.Text>
  );

  return (
    <Homepages>
      <h2 className="section-title section-title--small-indent">
        {_t("Reset password")}
      </h2>
      {isSuccess ? success : form}
    </Homepages>
  );
};
