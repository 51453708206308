import React from "react";
import { Homepages } from "../../../layouts";
import {
  MainBanner,
  Plans,
  Platforms,
  FAQ,
  Contacts,
  GamesCards,
} from "../sections";

const Home = () => {
  return (
    <Homepages>
      <MainBanner />
      <GamesCards />
      <Plans />
      <Platforms />
      <FAQ />
      <Contacts />
    </Homepages>
  );
};

export default Home;
