import "./index.scss";
import React, { useState } from "react";
import { PageTemplate } from "../PageTemplate";
import MainLayout from "../../../Layouts/MainLayouts";
import { useGetTechnicalAccountDashboard } from "../../apiHooks";
import { Filters, Item } from "./components";
import { Spin, Typography } from "antd";
import { _t } from "../../../Common/components/InjectIntlContext";
import moment from "moment";

export const Technical = () => {
  const [filters, setFilters] = useState({
    dates: {
      from: moment().startOf("month"),
      to: moment(),
    },
  });
  const { data, isLoading } = useGetTechnicalAccountDashboard({
    from: filters.dates.from.format("YYYY-MM-DD"),
    to: filters.dates.to.format("YYYY-MM-DD"),
  });

  return (
    <MainLayout>
      <PageTemplate>
        <div className="tech-dashboard">
          <Typography.Title style={{ marginBottom: 20 }} level={4}>
            {_t("tech_dashboard")}
          </Typography.Title>
          <Filters filters={filters} setFilters={setFilters} />
          <Spin spinning={isLoading}>
            {data && (
              <div className="tech-dashboard-grid">
                {data.models.map((item: any) => (
                  <Item key={item.project_id} {...item} />
                ))}
              </div>
            )}
          </Spin>
        </div>
      </PageTemplate>
    </MainLayout>
  );
};

export default Technical;
