import React from "react";
import { Form, Input, Checkbox, Spin, Radio } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../system/config";
import { getItem, getLocale } from "../../../system/helpers/localstorageHelper";
import { INVITATION_CODE } from "../../Landing/constants";
import { Link } from "react-router-dom";

export function RegistrationForm({ onSubmit, isLoading }) {
  const [form] = Form.useForm();
  const locale = getLocale();
  const recaptchaRef = React.createRef();
  const invitationCode = getItem(INVITATION_CODE);

  const formErrorMsg = {
    passMatch: _t("error_confirm_password_message"),
    terms: _t("accept_terms"),
  };

  const sendForm = (values) => {
    onSubmit({
      ...values,
      phone: `${values.phone} | plan [${values.plan}]`,
    });
  };
  return (
    <Form
      form={form}
      name="register"
      onFinish={sendForm}
      initialValues={{
        invitation_code: invitationCode,
      }}
      scrollToFirstError
      className="homepage-form"
    >
      <h2 className="section-title section-title--small-indent">
        {_t("sign_up")}
      </h2>
      <p className="homepage-form__subtitle">
        {_t("have_account") + " "}
        <Link to={APP_ROUTES.login.main}>{_t("sign_in")}!</Link>
      </p>

      {invitationCode && (
        <Form.Item name="invitation_code" hidden={true}>
          <Input />
        </Form.Item>
      )}

      <div className="form-plan-select">
        <Form.Item name="plan" initialValue="PRO">
          <Radio.Group>
            <Radio value="PRO">{_t("plan_title_pro")}</Radio>
            <Radio value="BEGINNER">{_t("plan_title_beginner")}</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <Form.Item
        name="login"
        rules={[
          {
            required: true,
            message: _t("enter_login_message"),
            whitespace: true,
          },
        ]}
      >
        <Input placeholder={_t("Login")} />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: _t("not_valid_email_message"),
          },
          {
            required: true,
            message: _t("input_email_message"),
          },
        ]}
      >
        <Input placeholder={_t("E-mail")} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: _t("input_password_message"),
          },
          {
            min: 6,
            message: _t("min_password_message"),
          },
        ]}
      >
        <Input.Password placeholder={_t("Password")} />
      </Form.Item>

      <Form.Item
        name="password_confirmation"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: _t("confirm_password_message"),
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(formErrorMsg.passMatch);
            },
          }),
        ]}
      >
        <Input.Password placeholder={_t("confirm_password")} />
      </Form.Item>

      <Form.Item
        name="phone"
        rules={[{ required: true, message: _t("Please input your contacts!") }]}
      >
        <Input placeholder={_t("Contacts")} />
      </Form.Item>

      <Form.Item
        name="terms"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(formErrorMsg.terms),
          },
        ]}
      >
        <Checkbox>
          {_t("agree_with")}{" "}
          <a href={APP_ROUTES.terms}> {_t("terms_conditions")}</a>
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="g-recaptcha-response"
        rules={[
          {
            required: true,
            message: _t("recaptcha_required"),
          },
        ]}
        className="recaptcha"
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={config.google_recaptcha_sitekey}
          hl={locale}
        />
      </Form.Item>
      <Spin spinning={isLoading}>
        <button
          className="homepage-button homepage-button--gradient"
          disabled={isLoading}
          type="submit"
        >
          <span>{_t("sign_up")}</span>
        </button>
      </Spin>
    </Form>
  );
}
