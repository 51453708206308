import React from "react";
import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSubaffiliatesTotals } from "../apiHooks";
import { CurrencyLabel } from "../../Common/components";
import { _t } from "../../Common/components/InjectIntlContext";
import { NoData } from "../../Common/components/NoData";
import {
  ADDITIONAL_CURRENCY_SYMBOL,
  ADDITIONAL_CURRENCY_SYMBOL_DATE,
} from "../../../system/config";
import {
  getCurrencySymbol,
  isAdditionalSymbolOrMixed,
} from "../../../common/helpers";
import config from "../../../system/config";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("reg_date"),
    dataIndex: "reg_date",
    key: "reg_date",
  },
  {
    title: _t("Login"),
    dataIndex: "login",
    key: "login",
  },
  {
    title: _t("subaffiliates_profit"),
    dataIndex: "subaffiliate_income",
    key: "subaffiliate_income",
    render: (val, record) => {
      return <CurrencyLabel value={val} />;
    },
  },
  {
    title: _t("your_profit"),
    dataIndex: "partner_income",
    key: "partner_income",
    render: (val, record) => {
      return <CurrencyLabel value={val} />;
    },
  },
];

export function SubAffiliatesWidget() {
  const { data, isLoading } = useSubaffiliatesTotals();
  const translatedColumns = columns(_t);
  return (
    <Card title={_t("all_subaffiliates")} style={{ height: "100%" }}>
      <Table
        loading={isLoading}
        rowKey="login"
        columns={translatedColumns}
        dataSource={data?.data?.subaffiliates}
        pagination={false}
        locale={{ emptyText: <NoData /> }}
      />
      <br />
      <p>
        {_t("income_from_subaffiliates")}
        <CurrencyLabel value={data?.data?.total_income} />
      </p>
    </Card>
  );
}
