import React from "react";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { Link, useHistory } from "react-router-dom";
import config from "../../../../system/config";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
import LandingLanguages from "../../../../modules/Landing/components/LandingLanguages";

const Header = ({ setIsMobileMenuOpened }) => {
  const history = useHistory();
  return (
    <header className="homepage-header">
      <Link to="/" className="header-logo">
        <img src={`${config.imagesDirectory}/logo.svg`} alt="" />
      </Link>
      <LandingLanguages />
      <div className="homepage-header__buttons">
        <button
          className="homepage-button homepage-button--small homepage-button--secondary"
          onClick={() => history.push(APP_ROUTES.login.main)}
        >
          <span>{_t("sign_in")}</span>
        </button>
        <button
          className="homepage-button homepage-button--small"
          onClick={() => history.push(APP_ROUTES.registration)}
        >
          <span>{_t("sign_up")}</span>
        </button>
      </div>
      <button className="burger-btn" onClick={setIsMobileMenuOpened}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
  );
};

export default Header;
