import React from "react";
import { Homepages } from "../../../layouts";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";

const items = (_t) => [
  {
    title: _t("offer_bettilt_title"),
    list: [
      _t("offer_bettilt_list_item_1"),
      _t("offer_bettilt_list_item_2"),
      _t("offer_bettilt_list_item_3"),
      _t("offer_bettilt_list_item_4"),
      _t("offer_bettilt_list_item_5"),
    ],
    logo: _t("offer_bettilt_logo"),
    website: _t("offer_bettilt_website"),
  },
  {
    title: _t("offer_bahsegel_title"),
    list: [
      _t("offer_bahsegel_list_item_1"),
      _t("offer_bahsegel_list_item_2"),
      _t("offer_bahsegel_list_item_3"),
      _t("offer_bahsegel_list_item_4"),
      _t("offer_bahsegel_list_item_5"),
    ],
    logo: _t("offer_bahsegel_logo"),
    website: _t("offer_bahsegel_website"),
  },
  {
    title: _t("offer_paribahis_title"),
    list: [
      _t("offer_paribahis_list_item_1"),
      _t("offer_paribahis_list_item_2"),
      _t("offer_paribahis_list_item_3"),
      _t("offer_paribahis_list_item_4"),
      _t("offer_paribahis_list_item_5"),
    ],
    logo: _t("offer_paribahis_logo"),
    website: _t("offer_paribahis_website"),
  },
];
const Offers = () => {
  const offerItems = items(_t);
  return (
    <Homepages isInnerPage>
      <section className="homepages-page">
        <h2 className="section-title section-title--small-indent">
          {_t("homepages_page_offers_title")}
        </h2>
        <p className="page-description-text">
          {_t("homepages_page_offers_text")}
        </p>
        <div className="offers-grid">
          {offerItems.map((item, idx) => (
            <div className="offer-card" key={idx}>
              <div className="offer-card__side">
                <div className="offer-card__image">
                  <img src={item.logo} loading="lazy" alt={item.title} />
                </div>
                <div className="offer-card__buttons">
                  {/*<a*/}
                  {/*  href={item.website}*/}
                  {/*  target="_blank"*/}
                  {/*  rel="noreferrer"*/}
                  {/*  className="homepage-button homepage-button--small homepage-button--secondary"*/}
                  {/*>*/}
                  {/*  <span>{_t("visit_website")}</span>*/}
                  {/*</a>*/}
                  <Link
                    to={APP_ROUTES.registration}
                    className="homepage-button homepage-button--small"
                  >
                    <span>{_t("sign_up")}</span>
                  </Link>
                </div>
              </div>
              <div className="offer-card__content">
                <h3 className="offer-card__title">{item.title}</h3>
                <ul className="offer-card__list">
                  {item.list.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Homepages>
  );
};

export default Offers;
