export const filterOptionsTypes = {
  numbers: [
    "first_deposit",
    "deposits_sum",
    "deposits_count",
    "net_gaming",
    "bonuses",
    "balance",
    "hits",
    "hosts",
    "regs",
    "rounds",
    "qualified_players",
    "first_deposits",
    "deposits",
    "withdraws",
    "comppoints",
    "casino_profit",
    "partner_income",
    "payouts",
    "fan_income",
  ],
  strings: [
    "gamer_id",
    "partner_id",
    "reg_date",
    "first_deposit_date",
    "reg_ip",
    "sub_id",
    "sub_id2",
    "country",
    "duplicates",
  ],
};

export const adminColumns = [
  "deposits",
  "ngr",
  "ngr1",
  "ngr2",
  "ggr",
  "ggr1",
  "ggr2",
  "royalty",
  "withdraws",
  "rs_income",
  "assured_income",
  "partner_income",
  "rs_income1",
  "rs_income2",
  "rs_income_rel_diff",
  "partner_income1",
  "partner_income2",
  "partner_income_rel_diff",
  "assured_income1",
  "assured_income2",
  "assured_income_rel_diff",
  "first_deposit",
  "first_deposit_diff",
  "first_deposits1",
  "first_deposits2",
  "first_deposits_diff",
  "first_deposits_rel_diff",
  "deposits_sum",
  "ggr_rel_diff",
  "transaction_commission",
  "fan_income",
  "ng",
  "gamers",
  "rounds",
];
