import React from "react";
import { useHistory } from "react-router-dom";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { Link } from "react-router-dom";
import config from "../../../../system/config";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

const items = (_t) => [
  {
    title: _t("navigation_link_about"),
    path: "/about",
    isAnchor: true,
  },
  {
    title: _t("navigation_link_plans"),
    path: "/plans",
    isAnchor: true,
  },
  {
    title: _t("navigation_link_platforms"),
    path: "/platforms",
    isAnchor: true,
  },
  {
    title: _t("conditions"),
    path: "/terms",
  },
  {
    title: _t("Contacts"),
    path: APP_ROUTES.contacts,
  },
];
const Footer = ({ setCurrentPage }) => {
  const history = useHistory();
  const footerNavItems = items(_t);
  const handleChangeMenu = (link, isAnchor) => {
    document
      .getElementById(`anchor-${link.replace("/", "")}`)
      ?.scrollIntoView({ behavior: "smooth" });
    setCurrentPage(link);
    history.push(link);
  };
  return (
    <footer className="homepage-footer">
      <ul className="footer-nav">
        {footerNavItems.map((item, idx) => (
          <li key={idx}>
            <button onClick={() => handleChangeMenu(item.path, item.isAnchor)}>
              {item.title}
            </button>
          </li>
        ))}
      </ul>
      <Link to="/" className="footer-logo">
        <img src={`${config.imagesDirectory}/logo.svg`} alt="" />
      </Link>
    </footer>
  );
};

export default Footer;
