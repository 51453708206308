import React, { useEffect } from "react";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import Layout from "antd/lib/layout";
import ReturnAdmin from "./special/ReturnAdmin";
import { useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../system/constants/constantsUrl";
import { getUserRoles } from "../../system/helpers/localstorageHelper";
import { permissions, ROLES } from "../../system/constants/roles";

const MainLayout = (props) => {
  const { headerLinks, withoutHeader } = props;
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const ROUTES_TECH_ACCOUNT = ["/chat", "/user", "/technical-dashboard"];
    if (localStorage.getItem("role") === ROLES.tech_account) {
      const isTechRoute = ROUTES_TECH_ACCOUNT.some((route) =>
        location.pathname.startsWith(route)
      );
      if (!isTechRoute) {
        history.push("/technical-dashboard");
      }
    }
  }, []);
  return (
    <Layout style={{ maxHeight: "100vh" }}>
      <ReturnAdmin />
      <Sidebar />
      <Layout>
        {!withoutHeader && <Header links={headerLinks} />}
        <div className="main-container">{props.children}</div>
      </Layout>
    </Layout>
  );
};

MainLayout.defaultProps = {
  withoutHeader: false,
  headerLinks: [],
};

export default MainLayout;
