import React, { useEffect } from "react";
import MainLayout from "../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { Card, Col, Row, Table } from "antd";
import Form from "antd/lib/form/Form";
import FormItem from "antd/es/form/FormItem";
import Input from "antd/lib/input";
import { Button } from "antd/es";
import { useApiPostFindGamers } from "./hooks";
import { generatePath, Link, useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

const GamersSearchPage = () => {
  const history = useHistory();
  const getGamerProfilePath = (projectId, gamerId) => {
    return generatePath(APP_ROUTES.dashboard.gamer_profile, {
      projectId,
      gamerId,
    });
  };
  const [mutate, { data, isLoading, isSuccess }] = useApiPostFindGamers();
  const handleGamersSearch = (values) => {
    mutate(values.id);
  };

  useEffect(() => {
    if (!isLoading && isSuccess && data.length === 1) {
      history.push(
        getGamerProfilePath(data[0]?.project_id, data[0]?.encrypted_id)
      );
    }
  }, [data, isLoading, isSuccess]);

  const columns: any = [
    {
      title: _t("gamer_real_id"),
      dataIndex: "id",
      key: "id",
      width: 150,
      render: (v, r) => (
        <Link to={getGamerProfilePath(r.project_id, r.id)} target="_blank">
          {v}
        </Link>
      ),
    },
    {
      title: _t("gamer_encrypted_id"),
      dataIndex: "encrypted_id",
      key: "encrypted_id",
      render: (v, r) => (
        <Link
          to={getGamerProfilePath(r.project_id, r.encrypted_id)}
          target="_blank"
        >
          {v}
        </Link>
      ),
    },
  ];
  return (
    <MainLayout>
      <PageTemplate title={_t(`gamers_search`)}>
        <Card>
          <Row>
            <Col xs={12} span={24}>
              <Form layout="vertical" onFinish={handleGamersSearch}>
                <FormItem
                  label={_t("find_gamers_input_label")}
                  name="id"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder={_t("find_gamers_input_placeholder")} />
                </FormItem>
                <Button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{ marginBottom: "15px" }}
                >
                  {_t("search")}
                </Button>
              </Form>
            </Col>
          </Row>
          {data?.length > 1 && (
            <Table
              dataSource={data || []}
              columns={columns}
              pagination={false}
            />
          )}
        </Card>
      </PageTemplate>
    </MainLayout>
  );
};

export default GamersSearchPage;
