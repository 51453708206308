import React, { useState } from "react";
import Section from "../Section";
import { useGetFAQ } from "./helpers";
import { getLocale } from "../../../../system/helpers/localstorageHelper";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { Spin } from "antd";

const FAQ = () => {
  const [category, setCategory] = useState("general");
  const faqMenu = ["general", "finance", "technical"];
  const { data, isLoading } = useGetFAQ({
    category,
    locale: getLocale(),
  });
  return (
    <Section id="faq" sectionClass="faq-section">
      <h2 className="section-title">{_t("FAQ")}</h2>
      <ul className="faq-menu">
        {faqMenu.map((item, idx) => (
          <li key={idx}>
            <button
              onClick={() => setCategory(item)}
              className={`${category === item ? "active" : ""}`}
            >
              <span>{_t(item === "finance" ? "financial" : item)}</span>
            </button>
          </li>
        ))}
      </ul>

      {isLoading ? (
        <Spin spinning={isLoading} />
      ) : (
        <div className="faq-grid">
          {data?.faq.map((item) => (
            <div key={item.id} className="faq-item">
              <h3
                className="faq-item__title"
                dangerouslySetInnerHTML={{
                  __html: item.translations[0]?.question.replace(
                    /<[^>]+>/g,
                    ""
                  ),
                }}
              ></h3>
              <p
                className="faq-item__text"
                dangerouslySetInnerHTML={{
                  __html: item.translations[0]?.answer.replace(/<[^>]+>/g, ""),
                }}
              ></p>
            </div>
          ))}
        </div>
      )}
    </Section>
  );
};

export default FAQ;
