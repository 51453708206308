import React from "react";
import { Card, Tag, Row } from "antd";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CurrencyLabel } from "../../Common/components";
import { UsersListResponse } from "../constants";
import { _t } from "../../Common/components/InjectIntlContext";
import Btn from "../../Common/components/Button";
import { Link } from "react-router-dom";
import { NoData } from "../../Common/components/NoData";
import { permissions } from "../../../system/constants/roles";
import { getItem } from "../../../system/helpers/localstorageHelper";

const columns = (_t: any): ColumnsType<any> => [
  {
    title: _t("Login"),
    dataIndex: "login",
    render: (v, r) => {
      return (
        <span>
          <small>{r.id}:</small> <strong>{v}</strong>
          <div>{r.email}</div>
          {r.manager ? <Tag color="green">{r.manager}</Tag> : <></>}
        </span>
      );
    },
  },
  {
    title: _t("Name"),
    dataIndex: "first_name",
    render: (v, r) => {
      return (
        <>
          {v} <i>{r.last_name}</i>
        </>
      );
    },
  },
  {
    title: _t("finances"),
    dataIndex: "payout",
    render: (v, r) => {
      return (
        <>
          <sub>b</sub>
          <CurrencyLabel value={r.balance * 100}></CurrencyLabel> /<sub>c</sub>
          <CurrencyLabel value={r.balance_current_period * 100}></CurrencyLabel>
          /<sub>t</sub>
          <CurrencyLabel value={r.profit * 100}></CurrencyLabel> /<sub>p</sub>
          <CurrencyLabel value={r.payouts * 100}></CurrencyLabel>
        </>
      );
    },
  },
  {
    title: _t("role_status"),
    dataIndex: "role_name",
    render: (v, r) => {
      return (
        <>
          <div dangerouslySetInnerHTML={{ __html: v }}></div>
          {r.activated ? (
            <Tag color="green">{_t("activated")}</Tag>
          ) : (
            <Tag>{_t("user_not_activated")}</Tag>
          )}
          {!r.email_verified && <Tag>{_t("not_activated")}</Tag>}
        </>
      );
    },
  },
  {
    title: _t("dates"),
    dataIndex: "created_at",
    render: (v, r) => {
      return (
        <>
          <Row>
            <Tag color="blue">{_t("registered")}</Tag> {v}
          </Row>
          {r.last_login ? (
            <Row>
              <Tag color="green">{_t("last_login")}</Tag>
              {r.last_login}
            </Row>
          ) : (
            <Row>
              <Tag color="red">{_t("never_logged_in")}</Tag>
            </Row>
          )}
        </>
      );
    },
  },
  {
    title: _t("Manage"),
    dataIndex: "id",
    render: (v, r) => {
      return (
        <span>
          {permissions.user_permission.admin.includes(getItem("role")) && (
            <Link to={`/login-by-id?user_id=${v}`}>
              <Btn type="default" title={_t("login_as")}></Btn>
            </Link>
          )}
          <Link to={`/users/profile/${v}`}>
            <Btn type="primary" title={_t("profile")}></Btn>
          </Link>
        </span>
      );
    },
  },
];

type UsersListWidgetProps = {
  data: UsersListResponse;
  isLoading: boolean;
  page: any;
  setPage: any;
  pageSize: any;
};
type UsersTableProps = {
  data: any;
  loading: boolean;
  page: any;
  setPage: any;
  pageSize: any;
};

function UsersTable({
  data,
  loading,
  page,
  setPage,
  pageSize,
}: UsersTableProps) {
  const s = columns(_t).filter((column: any) => {
    if (column.dataIndex === "payout") {
      return permissions.user_permission.admin.includes(getItem("role"));
    }
    return column;
  });
  return (
    <Table
      loading={loading}
      columns={s}
      dataSource={data?.data}
      rowKey={(r) => r.id}
      pagination={{
        total: data?.total,
        hideOnSinglePage: true,
        onChange: setPage,
        current: page,
        pageSize: pageSize,
        showSizeChanger: false,
      }}
      locale={{ emptyText: <NoData /> }}
    />
  );
}

export const UsersListWidget = ({
  data,
  isLoading,
  page,
  setPage,
  pageSize,
}: UsersListWidgetProps) => {
  return (
    <Card title="User list">
      <UsersTable
        loading={isLoading}
        data={data?.data}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
      />
    </Card>
  );
};
