import React from "react";
import Section from "../Section";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import config from "../../../../system/config";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";
const items = (_t) => [
  {
    type: "pro",
    title: _t("plan_title_pro"),
    text: _t("plan_text_pro"),
    list: [
      {
        text: _t("plan_list_item_register"),
        icon: "image_reg_account.svg",
      },
      {
        text: _t("plan_list_item_promo"),
        icon: "image_promo.svg",
      },
      {
        text: _t("plan_list_item_publish"),
        icon: "image_publish.svg",
      },
      {
        text: _t("plan_list_item_earn"),
        icon: "image_earn.svg",
      },
    ],
  },
  {
    type: "beginner",
    title: _t("plan_title_beginner"),
    text: _t("plan_text_beginner"),
    list: [
      {
        text: _t("plan_list_item_request"),
        icon: "image_reg_account.svg",
      },
      {
        text: _t("plan_list_item_training"),
        icon: "image_training.svg",
      },
      {
        text: _t("plan_list_item_join"),
        icon: "image_publish.svg",
      },
      {
        text: _t("plan_list_item_earn"),
        icon: "image_earn.svg",
      },
    ],
  },
];
const Plans = () => {
  const planCards = items(_t);
  const history = useHistory();

  return (
    <Section id="plans" sectionClass="plans-section">
      <h2 className="section-title">{_t("section_title_choose_plan")}</h2>

      <div className="plan-grid">
        {planCards.map((item, idx) => (
          <div className="plan-card" key={idx}>
            <h3 className="plan-card__title">{item.title}</h3>
            <h6 className="plan-card__text">{item.text}</h6>
            <ul className="plan-card__list">
              {item.list.map((item, idx) => (
                <li key={idx}>
                  <img
                    src={`${config.imagesDirectory}/plan/${item.icon}`}
                    alt=""
                  />
                  {item.text}
                </li>
              ))}
            </ul>
            {item.type === "pro" && (
              <button
                className="homepage-button"
                onClick={() => history.push(APP_ROUTES.registration)}
              >
                <span>{_t("sign_up")}</span>
              </button>
            )}
            {item.type === "beginner" && (
              <button
                className="homepage-button"
                onClick={() => history.push(APP_ROUTES.contacts)}
              >
                <span>{_t("leave_request")}</span>
              </button>
            )}
          </div>
        ))}
      </div>
    </Section>
  );
};

export default Plans;
