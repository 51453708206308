import React from "react";
import { _t } from "../../../../../Common/components/InjectIntlContext";

const Item = (props) => {
  return (
    <div className="tech-dashboard-item">
      <div className="tech-dashboard-item__top">
        <img src={`https://admin.garage99.stream${props.image}`} alt="" />
        <span>{props.name}</span>
      </div>
      <ul className="tech-dashboard-item__list">
        <li>
          <span>{_t("hits")}</span>
          <span>{props.hits}</span>
        </li>
        <li>
          <span>{_t("hosts")}</span>
          <span>{props.hosts}</span>
        </li>
        <li>
          <span>{_t("regs")}</span>
          <span>{props.regs}</span>
        </li>
        <li>
          <span>{_t("first_deposits")}</span>
          <span>{props.first_deposits}</span>
        </li>
        <li>
          <span>{_t("qualified_players")}</span>
          <span>{props.qualified_players}</span>
        </li>
      </ul>
    </div>
  );
};

export default Item;

// {
//   "project_id": 1023,
//   "name": "Bahsegel",
//   "image": "/images/project_logos/bahsegel-logo.svg",
//   "hits": "0",
//   "hosts": "0",
//   "regs": "163",
//   "first_deposits": "6",
//   "qualified_players": "0"
// }
