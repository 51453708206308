import React, { useEffect, useState } from "react";
import { RegistrationForm } from "../components/RegistrationForm";
import { useRegister } from "../apiHooks";
import { REGISTRATION_SUCCESS_MESSAGE } from "../constants";
import { WelcomeForm } from "../components/WelcomeForm";
import { Homepages } from "../../../layouts";

export function Registration() {
  const [register, { isLoading, isSuccess, data }] = useRegister();
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    if (isSuccess) {
      if (data.status === REGISTRATION_SUCCESS_MESSAGE) {
        setRegistered(true);
      }
    }
  }, [isSuccess]);

  return (
    <Homepages isAuth>
      {registered ? (
        <WelcomeForm data={data} />
      ) : (
        <RegistrationForm onSubmit={register} isLoading={isLoading} />
      )}
    </Homepages>
  );
}
