import React from "react";
import Section from "../Section";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import config from "../../../../system/config";

const Platforms = () => {
  return (
    <Section id="platforms" sectionClass="platforms-section">
      <div className="platforms-section__content">
        <h2 className="section-title">{_t("section_title_platforms")}</h2>
        <h6 className="platforms-section__text">
          {_t("platform_section_text")}
        </h6>
      </div>
      <div className="platforms-section__image">
        <img
          src={`${config.imagesDirectory}/platforms/platform.png`}
          loading="lazy"
          alt=""
        />
      </div>
    </Section>
  );
};

export default Platforms;
