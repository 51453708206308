export const ROLES = {
  admin: "admin",
  super: "super",
  media_buyer: "media_buyer",
  media_buyer_manager: "media_buyer_manager",
  media_buyer_partner: "media_buyer_partner",
  project_owner: "project_owner",
  user: "user",
  manager: "manager",
  tech_account: "tech_account",
};

export const defaultRedirect = "/login";

export const permissions = {
  dashboard: {
    header: {
      general: [ROLES.admin, ROLES.super],
      commisions: [ROLES.admin, ROLES.super],
      costs: [
        ROLES.admin,
        ROLES.super,
        ROLES.media_buyer,
        ROLES.media_buyer_manager,
        ROLES.media_buyer_partner,
      ],
    },
  },
  manage: {
    users: [ROLES.admin, ROLES.super, ROLES.project_owner, ROLES.manager],
    brands: [ROLES.admin, ROLES.super, ROLES.project_owner, ROLES.manager],
    managers: [ROLES.admin, ROLES.super, ROLES.project_owner, ROLES.manager],
    notifications: [
      ROLES.admin,
      ROLES.super,
      ROLES.project_owner,
      ROLES.manager,
    ],
    fraudsters: [ROLES.admin, ROLES.super, ROLES.project_owner],

  },
  content: {
    faq: [ROLES.admin],
    translation: [ROLES.admin, ROLES.manager],
    testimonials: [ROLES.admin],
  },
  reports: [ROLES.admin, ROLES.manager],
  chat: {
    admin: [ROLES.admin],
  },
  user_permission: {
    users: [
      ROLES.user,
      ROLES.media_buyer,
      ROLES.media_buyer_manager,
      ROLES.media_buyer_partner,
      ROLES.project_owner,
      ROLES.manager,
    ],
    user: [ROLES.user],
    admin: [ROLES.admin],
    platformUsers: [
      ROLES.user,
      ROLES.media_buyer,
      ROLES.media_buyer_manager,
      ROLES.media_buyer_partner,
      ROLES.project_owner,
      ROLES.manager,
      ROLES.admin,
    ],
    tech_account: [ROLES.tech_account],
  },
};
