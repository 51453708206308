import React from "react";
import { Card } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { Link, NavLink } from "react-router-dom";
import Button from "../../Common/components/Button";

export function WelcomeForm(data: any) {
  return (
    <>
      <h2 className="section-title">{_t("welcome")}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: _t("successful_registration_message"),
        }}
      />
      <br />
      <Link to={"/login"} className="homepage-button homepage-button--gradient">
        <span>{_t("sign_in")}</span>
      </Link>
    </>
  );
}
