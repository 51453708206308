import React from "react";
import Section from "../Section";
import { usePostContacts } from "./helpers";
import { Form, Input } from "antd";
import config from "../../../../system/config";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";

const Contacts = () => {
  const [sendContacts, { isLoading }] = usePostContacts();
  const [form] = Form.useForm();

  const submitForm = (values) => {
    console.log(values);
    sendContacts(values);
    form.resetFields();
  };
  return (
    <Section id="contacts" sectionClass="contact-section">
      <Form onFinish={submitForm} form={form} className="contact-form">
        <h2 className="contact-form__title">{_t("contact_section_title")}</h2>
        <p className="contact-form__text">{_t("contact_section_text")}</p>
        <div className="contact-form__wrap">
          <Form.Item
            name="text"
            className="contact-form__item"
            rules={[
              {
                required: true,
                message: _t("valid_contacts_msg"),
              },
            ]}
          >
            <Input placeholder={"Skype / Telegram / Mail "} />
          </Form.Item>
          <Form.Item
            name="name"
            className="contact-form__item"
            rules={[
              {
                required: true,
                message: _t("input_name"),
              },
            ]}
          >
            <Input placeholder={_t("Name")} />
          </Form.Item>

          <div className="contact-form__item">
            <button
              type="submit"
              className="homepage-button"
              disabled={isLoading}
            >
              <span>{_t("Send")}</span>
            </button>
          </div>
        </div>
        <div className="contact-form__image">
          <img src={`${config.imagesDirectory}/micro.png`} alt="" />
        </div>
      </Form>
    </Section>
  );
};

export default Contacts;
