import React from "react";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { useHistory } from "react-router-dom";
import { Icon } from "./components";
import { APP_ROUTES } from "../../../../system/constants/constantsUrl";

const items = (_t) => [
  {
    title: _t("navigation_link_about"),
    path: "/about",
    icon: "about",
    isAnchor: true,
  },
  {
    title: _t("navigation_link_plans"),
    path: "/plans",
    icon: "plans",
    isAnchor: true,
  },
  {
    title: _t("navigation_link_platforms"),
    path: "/platforms",
    icon: "platforms",
    isAnchor: true,
  },
  {
    title: _t("navigation_link_offers"),
    path: "/offers",
    icon: "offers",
  },
  {
    title: _t("FAQ"),
    path: "/faq",
    icon: "faq",
    isAnchor: true,
  },
  {
    title: _t("sign_up"),
    path: APP_ROUTES.registration,
    icon: "sign_up",
    isHighlighted: true,
  },
];
const HomepagesNav = ({
  setCurrentPage,
  currentPage,
  setIsMobileMenuOpened,
}) => {
  const navItems = items(_t);
  const history = useHistory();

  const handleChangeMenu = (link) => {
    document
      .getElementById(`anchor-${link.replace("/", "")}`)
      ?.scrollIntoView({ behavior: "smooth" });
    setIsMobileMenuOpened(false);
    history.push(link);
    setCurrentPage(link);
  };
  return (
    <nav className="homepages-nav">
      <ul>
        {navItems.map((item, idx) => {
          return (
            <li key={idx}>
              <button
                onClick={() => {
                  handleChangeMenu(item.path);
                }}
                className={`${item.path === currentPage ? "active" : ""} ${
                  item.isHighlighted ? "highlighted" : ""
                }`}
              >
                <span className="icon">
                  <Icon type={item.icon} />
                </span>
                {item.title}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default HomepagesNav;
