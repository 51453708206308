import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { Divider } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { useSelector } from "react-redux";
import RolesWrapper from "../../Common/components/RolesWrapper";
import { getUserId } from "../../../system/helpers/localstorageHelper";

export const SidebarItem = (props) => {
  const {
    subMenu,
    itemTitle,
    sidebarItemIcon,
    menuDivider,
    userZone,
    collapsed,
    logout,
    item,
    itemClassName,
    languageZone,
  } = props;
  const user = useSelector((state) => state?.user?.basicInfo);

  const MenuItems = {
    class: "ant-menu-item",
    active: "ant-menu-item-selected",
  };
  const userName = () => {
    return `${getUserId()}: ${user.login}`;
  };

  const links = (item) => (
    <RolesWrapper rolesHasAccess={item.permission}>
      <NavLink
        to={item.path}
        activeClassName={MenuItems.active}
        className={MenuItems.class}
        key={item.title}
      >
        {item.icon}
        <span className={itemClassName}>
          {!userZone ? _t(item.title) : userName()}
        </span>
      </NavLink>
    </RolesWrapper>
  );

  const languageLinks = (item) => (
    <NavLink
      to={item.path}
      activeClassName={MenuItems.active}
      className={MenuItems.class}
      key={item.code}
      onClick={(event) => {
        event.preventDefault();
        item.onClick(item.code);
      }}
    >
      {/*{item.icon}*/}
      <span className={itemClassName}>{item.title}</span>
    </NavLink>
  );

  return (
    <RolesWrapper rolesHasAccess={item.permission}>
      {menuDivider && <Divider />}
      {!!subMenu ? (
        <Menu.SubMenu icon={sidebarItemIcon} {...props} title={itemTitle}>
          {subMenu.map((item) =>
            !languageZone ? links(item) : languageLinks(item)
          )}
        </Menu.SubMenu>
      ) : (
        <Menu.Item {...props} key={item.key} title={item.itemTitle}>
          {links(item)}
        </Menu.Item>
      )}
      {userZone && !collapsed && (
        <span onClick={logout} className="logout-btn">
          {_t("Logout")}
        </span>
      )}
    </RolesWrapper>
  );
};
