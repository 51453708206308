import { REPORT_STATE_SELECTOR } from "./utils";
import { API_ROUTES } from "../../../../../system/constants/apiRoutes";
import { REPORT_COLUMNS_KEY, REPORT_DATE_KEY } from "./utils";
import { updateReportFilters } from "./redux/actions";

export const config = {
  columns: [
    "partner_id",
    "partner_login",
    "status_label",
    "role_label",
    "traffic_type_label",
    "reg_date",
    "cash_type_label",
    "hits",
    "hosts",
    "regs",
    "rounds",
    "qualified_players",
    "first_deposits",
    "rd",
    "deposits_count",
    "deposits",
    "multiacc_count",
    "withdraws",
    "comppoints",
    "rd",
    "rd_percent",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
    "assured_income",
    "partner_income",
    "fan_income",
  ],
  hiddenColumns: ["rounds", "assured_income"],
  subtotals: [
    "hits",
    "hosts",
    "regs",
    "withdraws",
    "multiacc_count",
    "rd",
    "deposits_count",
    "deposits",
    "bonus",
    "gamers",
    "rounds",
    "full_income",
    "comppoints",
    "ltv",
    "full_profit",
    "first_deposits",
    "qualified_players",
    "landing_hits",
    "landing_hosts",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
    "assured_income",
    "partner_income",
    "fan_income",
  ],
  filteredColumns: [
    "partner_login",
    "status_label",
    "role_label",
    "traffic_type_label",
    "reg_date",
    "cash_type_label",
    "hits",
    "hosts",
    "regs",
    "rounds",
    "qualified_players",
    "first_deposits",
    "multiacc_count",
    "rd",
    "deposits_count",
    "deposits",
    "withdraws",
    "comppoints",
    "partner_income",
    "assured_income",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
  ],
  columnsKey: REPORT_COLUMNS_KEY,
  dateKey: REPORT_DATE_KEY,
  stateSelector: REPORT_STATE_SELECTOR,
  api: API_ROUTES.reports.affiliates.path,
  apiKey: API_ROUTES.reports.affiliates.key,
  fileName: API_ROUTES.reports.affiliates.filename,
  dispatchCallback: updateReportFilters,
};
